const DEFAULT_MARKET = 'sv-se';

const MARKETS = {
  EN_US: 'en-us',
  EN_CA: 'en-ca',
  EN_DE: 'en-de',
  EN_NO: 'en-no',
  EN_EU: 'en-eu',
  EN_SE: 'en-se',
  EN_KR: 'en-kr',
  EN_JP: 'en-jp',
  EN_FI: 'en-fi',
  EN_DK: 'en-dk',
  SV_EU: 'sv-eu',
  SV_SE: 'sv-se',
  SV_NO: 'sv-no',
  SV_DK: 'sv-dk',
  SV_FI: 'sv-fi',
  DE_EU: 'de-eu',
  DE_DE: 'de-de',
  EN_GLOBAL: 'en-eu', // change to 'en-gl' later, @HOUD-1518
};

const OLD_MARKETS = {
  EN_CN: 'en-cn',
  EN_TW: 'en-tw',
};

const COUNTRY_CODE_TO_MARKET = {
  SE: MARKETS.SV_SE,
  US: MARKETS.EN_US,
  CA: MARKETS.EN_CA,
  DE: MARKETS.DE_DE,
  NO: MARKETS.EN_NO,
  JP: MARKETS.EN_JP,
  AT: MARKETS.EN_EU,
  BE: MARKETS.EN_EU,
  BG: MARKETS.EN_EU,
  HR: MARKETS.EN_EU,
  CZ: MARKETS.EN_EU,
  DK: MARKETS.EN_DK,
  EE: MARKETS.EN_EU,
  FI: MARKETS.EN_FI,
  FR: MARKETS.EN_EU,
  KR: MARKETS.EN_KR,
  GI: MARKETS.EN_EU,
  GR: MARKETS.EN_EU,
  NL: MARKETS.EN_EU,
  HU: MARKETS.EN_EU,
  IE: MARKETS.EN_EU,
  IT: MARKETS.EN_EU,
  LV: MARKETS.EN_EU,
  LU: MARKETS.EN_EU,
  MT: MARKETS.EN_EU,
  PL: MARKETS.EN_EU,
  PT: MARKETS.EN_EU,
  RO: MARKETS.EN_EU,
  SK: MARKETS.EN_EU,
  SI: MARKETS.EN_EU,
  ES: MARKETS.EN_EU,
  GB: MARKETS.EN_EU,
  LT: MARKETS.EN_EU,
  CY: MARKETS.EN_EU,
  IS: MARKETS.EN_EU,
  CH: MARKETS.EN_EU,
  UA: MARKETS.EN_EU,
  AD: MARKETS.EN_EU,
  LI: MARKETS.EN_EU,
};

module.exports = { MARKETS, OLD_MARKETS, COUNTRY_CODE_TO_MARKET };
