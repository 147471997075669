import {
  DepictProduct,
  ProductCardProps,
  GroupVariants,
  NavigateFunction,
} from '../types';

const noDecimalCurrency = ['SEK', 'NOK'];

export const parsePrice = (
  price: number,
  locale: string,
  currency = 'EUR'
): string => {
  const options = {};

  if (noDecimalCurrency.includes(currency)) {
    options['minimumFractionDigits'] = 0;
    options['maximumFractionDigits'] = 0;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: locale?.toLocaleLowerCase() === 'sv' ? 'code' : 'symbol',
    ...options,
  }).format(price);
};

type ParseProductDataReturn = Partial<ProductCardProps['product']>;

export const parseProductData = (
  display: DepictProduct,
  locale: string,
  currency: string
): ParseProductDataReturn => {
  if (!display) return null;

  const d = display;
  if (!d.variant_displays) return null;

  const v = d.variant_displays[d.variant_index];

  const variants = groupVariants(d.variant_displays, locale, currency);

  return {
    variant_index: d.variant_index,
    id: v.product_query_id.split('?color=')[0],
    shortDescription: v.description,
    title: v.title,
    labels: [],
    page_url: v.page_url,
    key: `${v.product_query_id}-${d.variant_index}`,
    sustainabilityOptions: v.sustainability_icons,
    type: v.labels.some((l) => l.title === 'Reuse')
      ? 'ReuseProduct'
      : 'HoudiniProduct',
    variants,
    selectedVariantIndex:
      variants.findIndex(
        (v) => v.id === d.variant_displays[d.variant_index].color_variant_id
      ) || 0,
  };
};

const groupVariants = ((variants, locale, currency) => {
  if (!variants) return [];

  const groupedVariants = variants.reduce((acc, variant) => {
    const existingColor = acc.find((c) => c.id === variant.color_variant_id);

    if (existingColor) {
      existingColor.sizes = [
        ...existingColor.sizes,
        {
          articleId: variant.size,
          price: {
            currency: 'EUR',
            list: parsePrice(variant.original_price, locale, currency),
            reduced:
              variant.original_price !== variant.sale_price
                ? parsePrice(variant.sale_price, locale, currency)
                : null,
            sortingPrice: variant.sale_price.toString(),
          },
          stockBalance: variant.in_stock ? 1 : 0,
          title: variant.size,
          shortTitle: variant.size,
        },
      ];
    } else {
      acc.push({
        id: variant.color_variant_id,
        color: {
          id: variant.color_hex,
          filterColor: variant.color_name,
          title: variant.specific_color_name,
        },
        images: variant.image_urls.map((i, idx) => ({
          mediaType:
            idx === 0 ? 'ModelImage' : idx === 1 ? 'IsolatedImage' : 'Image',
          urlPath: i,
        })),
        labels: variant.labels,
        displaySizesInQuickView: variant.display_sizes_in_quick_view,
        sizes: [
          {
            articleId: variant.size,
            price: {
              currency: currency,
              list: parsePrice(variant.original_price, locale, currency),
              reduced:
                variant.original_price !== variant.sale_price
                  ? parsePrice(variant.sale_price, locale, currency)
                  : null,
              sortingPrice: variant.sale_price.toString(),
            },
            stockBalance: variant.in_stock ? 1 : 0,
            title: variant.size,
            shortTitle: variant.size,
          },
        ],
      } satisfies ReturnType<GroupVariants>[number]);
    }
    return acc;
  }, []);
  return groupedVariants;
}) satisfies GroupVariants;

const routesMap = {
  search: {
    pathname: '/SearchResultPage',
  },
  product: {
    pathname: '/HoudiniProduct',
  },
};

/**
 * @description
 * navigate to a relative url on Depict router change.
 * Fixes issues with next/router not being able to navigate to relative urls or hard-refreshing the page.
 * @param relativeUrl - relative url to navigate to
 * @param replace - replace the current history entry
 * @param router - next router instance
 * @returns void
 */
export const onDepictNavigate = ((relativeUrl, { replace }, router) => {
  if (!router) {
    console.error('onDepictNavigate: router is not defined.');
    return;
  }

  if (!relativeUrl) {
    console.error('onDepictNavigate: relativeUrl is not defined.');
    return;
  }

  if (router) {
    if (replace) {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
          },
        },
        relativeUrl,
        { shallow: true }
      );
    } else {
      const url = new URL(relativeUrl, window.location.href);
      router.push(
        {
          pathname: relativeUrl.includes('search')
            ? routesMap.search.pathname
            : routesMap.product.pathname,
          query: {
            ...router.query,
            ...Object.fromEntries(url.searchParams.entries()),
            id: url.pathname,
          },
        },
        `${url.pathname}${url.search}`,
        {
          scroll: true,
        }
      );
    }
  }
}) satisfies NavigateFunction;

export const depictPDPView = (productId: string) => {
  window.dpq('setProductId', productId);
};

export const depictAddToCart = (productId: string) => {
  window.dpq('addToCart', productId);
};

type purchaseData = {
  transaction_id: string;
  items: Array<{
    item_id: string;
    price: number;
    quantity: number;
    baseProductId: string;
  }>;
  currency: string;
};

export const depictPurchase = (data: purchaseData) => {
  window.dpq('purchase', data);
};

export const depictSetUserId = (userId: string) => {
  // TODO: Add proper implementation here so that depict is aware of the logged in user
  // window.dpq('setUserId', userId);
};
