/* eslint-disable no-use-before-define */
import NProgress from 'nprogress';
import 'swiper/css';
import App from 'next/app';
import Head from 'next/head';
import React from 'react';
import PropTypes from 'prop-types';
import { get, getOr, includes, isEmpty, throttle } from 'lodash/fp';
import { withCookies } from 'react-cookie';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import Router, { withRouter } from 'next/router';
import isMobileJS from 'ismobilejs';
import deepEqual from 'deep-equal';
import 'url-search-params-polyfill';
import { Provider } from 'react-redux';
import { DepictProvider } from '@depict-ai/react-ui';

import configureStore, { connect } from 'store/store';
import {
  GET_BOOTSTRAP_RESPONSE,
  GET_MENU_RESPONSE,
} from 'store/actions/actionTypes';
import {
  bootstrapRequest,
  cartRequest,
  marketsRequest,
  menuRequest,
  removeViewstate,
  setViewstate,
} from 'store/actions/actions';

import { NoAPIErrorPage } from 'components/Error/NoAPIErrorPage';
import ZendeskChat from 'components/ZendeskChat/ZendeskChat';
import { AppScripts } from 'components/AppScripts/AppScripts';

import { sizes } from 'styles/variables';
import 'styles/nprogress.css';
import 'styles/css/typekit.css';
import 'styles/scss/houdini-depict-styling.scss';

import { setServerResponseStatusCode } from 'helpers/setServerResponseStatusCode';
import { splitMarket } from 'helpers/splitMarket';
import { voyadoCartTracking } from 'helpers/voyado';
import sentry from 'helpers/sentry';
import { loadAvantScript } from 'helpers/loadAvantScript';
import { onDepictNavigate } from 'helpers/depict';

import { DEFAULT_MARKET, MARKETS } from 'constants/serverConstants';
import { FAILURE } from 'constants/requestStatuses';
import { LAST_LIST_VIEW } from 'constants/cookies';
import { LOGIN, RESET_PASSWORD } from 'constants/modals';
import {
  MODAL_PARAM,
  RESET_PASSWORD_PARAM,
  VERIFY_EMAIL_PARAM,
} from 'constants/queryStrings';
import { DEPICT_LOCALES, DEPICT_MERCHANT_ID } from 'constants/depict';
import { CATEGORY_PAGE, MAIN_CATEGORY_PAGE } from '../constants/cmsTypes';

const { captureException } = sentry();
class MyApp extends App {
  constructor(props) {
    super(props);

    this.handleResize = throttle(300, () => {
      window.requestAnimationFrame(() => {
        const width = Math.min(window.screen.width, window.innerWidth);
        const isMobile = width <= sizes.medium;
        const isMobileMin = width < sizes.medium;
        const current = get('viewState.isMobile', this.props);
        if (isMobile !== current) {
          this.props.setViewstate('isMobile', isMobile);
        }
        const currentMin = get('viewState.isMobileMin', this.props);
        if (isMobileMin !== currentMin) {
          this.props.setViewstate('isMobileMin', isMobileMin);
        }
      });
    });

    this.handleResize = this.handleResize.bind(this);
    this.addAvantLinkScripts = this.addAvantScript.bind(this);
    this.routeChangeStartHandler = this.routeChangeStartHandler.bind(this);
    this.routeChangeCompleteHandler =
      this.routeChangeCompleteHandler.bind(this);
    this.openModalFromParams = this.openModalFromParams.bind(this);
    this.handleProductListLastScrollPos =
      this.handleProductListLastScrollPos.bind(this);
  }

  static async getInitialProps(props) {
    const { Component, ctx } = props;
    const { res } = ctx;

    if (ctx.query?.slug?.includes('react_devtools_backend_compact.js.map')) {
      return null;
    }

    try {
      const { store, query, isServer, req, locale } = ctx;

      const market = locale;

      if (isServer) {
        const [language, country] = splitMarket(market);
        store.dispatch(setViewstate('market', market));
        store.dispatch(setViewstate('country', country));
        store.dispatch(setViewstate('language', language));

        // Get mobile bool from user agent.
        const userAgent = req
          ? req.headers['user-agent']
          : window.navigator.userAgent;
        const isMobile = isMobileJS(userAgent).any;

        store.dispatch(setViewstate('isMobile', isMobile));
        store.dispatch(setViewstate('isMobileMin', isMobile));

        // Initiate bootstrap request.
        store.dispatch(bootstrapRequest({ market }));

        store.dispatch(menuRequest({ market }));
        // store.dispatch(redirectsRequest({ market }));
        store.dispatch(marketsRequest({ market }));

        if (query.ExclusiveDeals || query.exclusivedeals) {
          // Requests to get discounted prices needs to be made client side so do not do it here
          // Would like to, but no
          store.dispatch(
            setViewstate(
              'discountCode',
              query.ExclusiveDeals || query.exclusivedeals
            )
          );
        }

        await setServerResponseStatusCode(
          ctx,
          [GET_BOOTSTRAP_RESPONSE, GET_MENU_RESPONSE],
          'bootstrap'
        );
      }

      let pageProps = {};
      const bootstrapStatus = store.getState()?.bootstrap?.request?.status;

      if (bootstrapStatus === FAILURE) {
        ctx.res.statusCode = 500;
      } else if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(props);
      }

      return {
        pageProps,
        statusCode: ctx.res && ctx.res.statusCode,
        market,
      };
    } catch (error) {
      captureException(error, ctx);
      return {
        statusCode: ctx.res && ctx.res.statusCode,
      };
    }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      captureException(error, { errorInfo });
    }
  }

  componentDidMount() {
    const { market, cookies, router } = this.props;

    // set user country and language if it was geolocated
    if (cookies.get('User-Country')) {
      const country = cookies.get('User-Country');
      const market = cookies.get('User-Market');
      const countryName = cookies.get('User-Country-Name');
      this.props.setViewstate('country', country);
      this.props.setViewstate('language', market.split('-')[0]);
      this.props.setViewstate('countryName', countryName);

      // if for any reason remembered locale and set locale don't match, set up the locale correctly
      // one reason might be when user visits a link with the locale already included
      // we don't want to force them to a specific market but instead allow
      // browsing through this visited market
      if (this.props.router.locale !== market) {
        const country = this.props.router.locale.split('-')[1];
        const language = this.props.router.locale.split('-')[0];
        this.props.setViewstate('country', country);
        this.props.setViewstate('language', language);
      }
    }

    if (
      router?.state?.query?.ExclusiveDeals ||
      router?.state?.query?.exclusivedeals
    ) {
      store.dispatch(
        setViewstate(
          'discountCode',
          router?.state?.query?.ExclusiveDeals ||
            router?.state?.query?.exclusivedeals
        )
      );
    }

    // set initial route in routerHistory
    this.routeChangeCompleteHandler(get(['router', 'asPath'], this.props));

    // only trigger a visit if the loaded page is not /checkout or /confirm routes
    if (
      !includes('/confirm', get(['router', 'asPath'], this.props)) &&
      !includes('/checkout', get(['router', 'asPath'], this.props))
    ) {
      const currentTime = Date.now();
      const timeBetweenVisits = 1000 * 60 * 120; // 120 minutes
      const timeStampToCountAsNewVisit =
        parseInt(get(['cookies', 'cookies', 'LAST_VISIT'], this.props), 10) +
        timeBetweenVisits;

      if (
        !timeStampToCountAsNewVisit ||
        currentTime > timeStampToCountAsNewVisit
      ) {
        // set the current time as LAST_VISIT time
        this.props.cookies.set('LAST_VISIT', currentTime);

        // increase NUMBER_OF_VISITS cookie by one
        this.props.cookies.set(
          'NUMBER_OF_VISITS',
          parseInt(
            getOr(0, ['cookies', 'cookies', 'NUMBER_OF_VISITS'], this.props),
            10
          ) + 1,
          {
            maxAge: 60 * 60 * 24 * 365, // 1 year, time is set in seconds
          }
        );
      }
    }

    // This enables history navigation with filters

    // Add AvantLink scripts if market is US or CA
    if (market === MARKETS.EN_US || market === MARKETS.EN_CA) {
      this.addAvantScript();
    }

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' });
    }

    this.handleResize();
    const params = new URLSearchParams(window.location.search);
    this.props.cartRequest({
      market,
      ...(params.has('cartId') && { cartId: params.get('cartId') }),
    });
    window.addEventListener('resize', this.handleResize);
    Router.events.on('routeChangeStart', this.routeChangeStartHandler);
    Router.events.on('routeChangeComplete', this.routeChangeCompleteHandler);
    Router.events.on('routeChangeError', () => NProgress.done());

    // Run getInitialState when using browsers "back"-button
    Router.beforePopState(({ url, as, options }) => {
      if (options.shallow) {
        Router.replace(url, as, {});
        return false;
      }
      return true;
    });

    if (params.has(MODAL_PARAM)) {
      this.openModalFromParams(params);
    }

    // Opens login-modal if verify param exists
    if (params.has(VERIFY_EMAIL_PARAM)) {
      this.props.setViewstate('activeModal', LOGIN);
    }
    if (params.has(RESET_PASSWORD_PARAM)) {
      this.props.setViewstate('activeModal', RESET_PASSWORD);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(this.props.cart, prevProps.cart)) {
      voyadoCartTracking(this.props.cart, this.props.market);
    }
  }

  addAvantScript() {
    const {
      router: { pathname },
    } = this.props;

    // Load script separately on confirm page
    if (pathname.toLowerCase() === '/confirm') return;

    loadAvantScript();
  }

  routeChangeStartHandler() {
    // Start nProgress
    NProgress.start();

    // reset viewstate on route change start
    this.props.removeViewstate('activeModal');
    this.props.removeViewstate('activeMegaMenu');
  }

  routeChangeCompleteHandler(url) {
    const {
      routerHistory,
      pathHistory,
      market,
      router: { pathname },
    } = this.props;

    NProgress.done();

    const urlWithoutQueryString = url.split('?')[0];

    this.props.setViewstate('pathHistory', [...pathHistory, pathname]);

    // fetch latest cart data on route change
    this.props.cartRequest({ market });

    if (urlWithoutQueryString) {
      this.props.setViewstate('routerHistory', [
        ...routerHistory,
        urlWithoutQueryString,
      ]);
    }

    this.handleProductListLastScrollPos(pathname);
  }

  /**
   * Remove LAST_LIST_VIEW cookie if going from /HoudiniProduct
   * to other page than the one saved in the cookie.
   * This will run after the visited page is loaded which is good. It's safe to remove now
   */
  handleProductListLastScrollPos(pathname) {
    const { cookies } = this.props;
    if (
      typeof cookies.get(LAST_LIST_VIEW) !== 'undefined' &&
      pathname !== '/HoudiniProduct'
    ) {
      cookies.remove(LAST_LIST_VIEW);
    }
  }

  openModalFromParams(params) {
    this.props.setViewstate('activeModal', params.get('modal').toUpperCase());

    // Clear query string after the modal is open
    window.history.replaceState(null, null, window.location.pathname);
  }

  render() {
    const {
      Component,
      pageProps,
      store,
      statusCode,
      market,
      bootstrap,
      categorySystemId,
    } = this.props;
    const marketLanguage = market?.split('-')[0];

    if (isEmpty(bootstrap) || statusCode === 500) {
      return <NoAPIErrorPage marketLanguage={marketLanguage} />;
    }

    const depictProviderOptions = {
      displayTransformers: {
        products: async (options) => {
          const { market } = options;
          const product_ids = options.displays.map(
            (display) =>
              display.variant_displays[
                display.variant_index
              ].product_query_id.split('?')[0]
          );

          const requestPromises = [];

          const length = product_ids.length >= 20 ? product_ids.length : 20;

          for (let x = 0; x < Math.floor(length / 20); x++) {
            const params = new URLSearchParams();
            params.append('market', market);

            for (let y = x * 20; y < (x + 1) * 20; y++) {
              params.append('productIds', product_ids[y]);
            }

            requestPromises.push(
              fetch(
                `${process.env.NEXT_PUBLIC_API_BASE_URL}/products/listPrices?${params}`,
                {
                  credentials: 'include',
                }
              ).then((res) => res.json())
            );
          }

          const data = await Promise.all(requestPromises);

          return options.displays.reduce((result, display) => {
            const selectedVariant =
              display.variant_displays[display.variant_index];
            const matchedProduct = data
              .flat()
              .find(
                ({ id }) =>
                  selectedVariant.product_query_id.split('?')[0] ===
                  id.split('/')[id.split('/').length - 1]
              );

            if (!matchedProduct) return result;

            const currentVariant = matchedProduct.variants.find(
              ({ articleModelColorId }) =>
                selectedVariant.color_variant_id === articleModelColorId
            );

            if (!currentVariant) return result;

            const variantDisplays = display.variant_displays.reduce(
              (result, variantDisplay, index) => {
                const isCategoryPage =
                  this.props.currentPage?.type === MAIN_CATEGORY_PAGE ||
                  this.props.currentPage?.type === CATEGORY_PAGE;

                const matchedVariant = matchedProduct.variants.find(
                  ({ articleModelColorId }) =>
                    variantDisplay.color_variant_id === articleModelColorId &&
                    (isCategoryPage
                      ? variantDisplay.category_ids.includes(categorySystemId)
                      : true)
                );

                if (!matchedVariant) {
                  return result;
                }

                result.push({
                  ...variantDisplay,
                  ...(matchedVariant.price?.reducedValue
                    ? {
                        sale_price: parseInt(matchedVariant.price.reducedValue),
                      }
                    : {}),
                });

                return result;
              },
              []
            );

            const updatedVariantIndex = variantDisplays.findIndex(
              ({ product_id }) => product_id === selectedVariant.product_id
            );

            result.push({
              ...display,
              variant_displays: variantDisplays,
              variant_index: updatedVariantIndex,
            });

            return result;
          }, []);
        },
      },
      merchant: DEPICT_MERCHANT_ID,
      market,
      search: { searchPagePath: `${market}/search` },
      navigateFunction: (url, options) =>
        onDepictNavigate(url, options, Router),
      locale: DEPICT_LOCALES[marketLanguage] || DEPICT_LOCALES.en,
    };

    return (
      <DepictProvider {...depictProviderOptions}>
        <Provider store={store}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
          </Head>
          <AppScripts marketLanguage={marketLanguage} />
          <Component {...pageProps} />
          {/* ? Placed Zendesk here to avoid duplicated script loads */}
          <ZendeskChat />
        </Provider>
      </DepictProvider>
    );
  }
}

MyApp.defaultProps = {
  routerHistory: [],
  pathHistory: [],
};

MyApp.propTypes = {
  routerHistory: PropTypes.arrayOf(PropTypes.string),
  pathHistory: PropTypes.arrayOf(PropTypes.string),
};

export default withRedux(configureStore)(
  withRouter(
    withReduxSaga(
      withCookies(
        connect(
          MyApp,
          { setViewstate, removeViewstate, cartRequest },
          (store, props) => {
            const currentPage = get(
              ['editorial', 'data', props.pageProps?.id],
              store
            );
            return {
              currentPage,
              routerHistory: get(['viewState', 'routerHistory'], store),
              pathHistory: get(['viewState', 'pathHistory'], store),
              bootstrap: store?.bootstrap?.data,
              cart: store?.cart?.data,
              markets: store?.markets?.data,
              categorySystemId: store?.viewState?.categorySystemId,
            };
          }
        )
      )
    )
  )
);
